import './ui/parallax.js';
import './ui/themeToggle.js';
import './utils/browser-sync.js';
import { isBrowserSyncEnabled } from './utils/browser-sync.js';
import * as Bootstrap from 'bootstrap';
import * as Popper from '@popperjs/core';
import Tooltip from 'bootstrap/js/dist/tooltip';
import '@fortawesome/fontawesome-free/js/all';
import $ from 'jquery';
__webpack_public_path__ = '/assets/';

export const App = () => {
  document.addEventListener('DOMContentLoaded', () => {
    isBrowserSyncEnabled();

    if (window.location.pathname === '/login') {
      import(
        /* webpackChunkName: 'chunks/login_controller' */ './ui/login.js'
      ).then((module) => {
        module.default();
      });
    }

    if ($('body').hasClass('fursuit-page')) {
      import(
        /* webpackChunkName: 'chunks/confetti_controller' */ './ui/confetti.js'
      ).then((module) => {
        module.default();
      });
    }

    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]',
    );

    if (tooltipTriggerList) {
      const tooltipList = [...tooltipTriggerList].map(
        (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl),
      );
    }
  });
};

App();
